<template>
  <div class="audience-count" data-cy="audience-count" v-if="!otoScoreIsEnabled">
    <p>
      {{ $t(`audiences.${people === undefined ? 'add-condition' : 'people-in-audience'}`) }}
    </p>
    <p class="number">
      <i class="icon icon-customer" data-cy="audience-count-icon"></i>
      <span v-if="showCount" data-cy="audience-count-number">{{ countPeople }}</span>
      <span v-else>-</span>
      <i
        v-if="isLookALikeActive"
        class="icon icon-question-12px tooltip-audience-count"
        v-tooltip="{
          theme: 'default',
          content: `<div class='tooltip-expansion-count'>${updateTitleExpansion}</div>`,
          distance: 8,
          html: true,
        }"
      ></i>
      <span v-if="showTime" class="time">
        <span v-if="showCalculating" class="time">{{ $t('audiences.calculating') }}...</span>
        <span v-else-if="time < 5">{{ $t('audiences.updatedAt.now') }}</span>
        <span v-else-if="time >= 5 && time < 60">{{
          $t('audiences.updatedAt.atTime', { time: Number.parseInt(time) })
        }}</span>
        <span v-else-if="time > 60">{{ $t('audiences.updatedAt.long-time') }}</span>
      </span>
    </p>
  </div>

  <div class="audience-count" v-else>
    <span class="audience-count-title">
      {{ $t('audiences.title') }}

      <el-popover effect="dark" trigger="hover" placement="top" :width="380">
        <p v-html="$t(`audiences.otometro.tooltips.people`)" class="otometro__tooltip__content"></p>
        <template #reference>
          <i class="icon icon-question-12px" />
        </template>
      </el-popover>
    </span>

    <div>
      <div class="audience-count__error" v-if="errorCount && !loadingCount">
        <i class="icon icon-customer"></i>
        <span>{{ $t('_error-message') }}</span>
      </div>

      <div class="loading" v-if="loadingCount">
        <el-skeleton :loading="true" animated>
          <template #template>
            <el-skeleton-item variant="p" style="height: 20px; width: 110px" />
          </template>
        </el-skeleton>
        <span class="time">{{ $t('audiences.calculating') }}...</span>
      </div>

      <p class="number" v-else>
        <i class="icon icon-customer" data-cy="audience-count-icon"></i>
        <span v-if="Object.keys(query).length !== 0">{{ countPeople }}</span>
        <span v-else>-</span>
        <i
          v-if="isLookALikeActive"
          class="icon icon-question-12px tooltip-audience-count"
          v-tooltip="{
            theme: 'default',
            content: `<div class='tooltip-expansion-count'>${updateTitleExpansion}</div>`,
            distance: 8,
            html: true,
          }"
        ></i>
        <span v-if="showTime" class="time">
          <span v-if="time < 5">{{ $t('audiences.updatedAt.now') }}</span>
          <span v-if="time >= 5 && time < 60">{{
            $t('audiences.updatedAt.atTime', { time: Number.parseInt(time) })
          }}</span>
          <span v-if="time > 60">{{ $t('audiences.updatedAt.long-time') }}</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { formatDecimal } from '@/utilities/formatters';

export default {
  props: {
    people: {
      type: Number,
      default: null,
    },
    time: {
      type: Number,
      default: null,
    },
    isView: {
      type: Boolean,
      default: true,
    },
    otoScoreIsEnabled: {
      type: Boolean,
      required: true,
    },
    loadingCount: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showCount() {
      const hasQuery = Object.keys(this.query).length;
      return (hasQuery && !this.isView) || (this.isView && this.people !== undefined && !this.loadingCount);
    },
    showTime() {
      return Object.keys(this.query).length && this.people !== undefined && !this.isView;
    },
    showCalculating() {
      return this.loadingCount && !this.isView;
    },
    countPeople() {
      const expansionData = this.lookALikeData[this.$store.state.audience.expansion];
      return this.isLookALikeActive && expansionData
        ? formatDecimal(this.people + expansionData, 2)
        : formatDecimal(this.people, 2);
    },
    updateTitleExpansion() {
      return `Audiência: <b>${formatDecimal(this.people, 2)}</b><br>Expansão: <b>
        ${formatDecimal(this.lookALikeData[this.$store.state.audience.expansion], 2)}</b>`;
    },
    ...mapState('audience', ['requestCount', 'lookALikeData', 'isLookALikeActive', 'errorCount']),
    ...mapGetters('audience', ['query']),
  },
};
</script>

<style lang="scss" scoped>
.audience-count {
  &-title {
    color: #555555;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;

    i {
      margin-left: 5px;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    margin-top: 7px;

    span {
      font-size: 12px;
      font-weight: 400;
      color: #555;
    }
  }

  .loading {
    display: flex;
    margin-top: 8px;
  }

  .icon {
    margin-right: 5px;
    color: $gray-600;
  }

  p {
    color: $gray-800;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0;
    cursor: default;
    &.number {
      font-size: 16px;
      margin-top: 7px;
    }
  }
  .time {
    font-size: 12px;
    font-weight: normal;
    color: $gray-600;
    display: block;
    margin-left: 14px;
    cursor: default;
  }
  .tooltip-audience-count {
    font-size: 16px;
    margin: 2px 0 0 6px;
    color: $gray-500;
  }
}
</style>

<style lang="scss">
.tooltip-expansion-count {
  text-align: left;

  b {
    text-decoration: none !important;
  }
}
</style>
