import { getDataIngestionData } from '@/services/dataIngestionHistory';
import { DataIngestionHistoryResponse } from '@/features/DataIngestionHomeAlerts/models';

type Options = { page?: number; limit?: number };

export const fetchDataIngestionTodayAlerts = ({ limit, page }: Options) =>
  getDataIngestionData({
    limit,
    page,
  }).then(response => formatResponse(response?.data));

const formatResponse = (response: DataIngestionHistoryResponse) => {
  const today = new Date().toISOString().split('T')[0];

  return {
    todayAlerts: response?.data[today]?.alerts || [],
  };
};
