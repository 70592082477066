import { TotalizersResponse, TableParams } from '../models';
import { getTableTotalizers } from '@/features/EngagementReport/services';

export const fetchTotalizers = ({
  preset,
  limit,
  page,
  orderBy,
  query,
  campaignId,
  storeId,
  sellerId,
  cluster,
}: TableParams) =>
  getTableTotalizers({
    preset,
    limit,
    page,
    orderBy,
    query,
    campaignId,
    storeId,
    sellerId,
    cluster,
  }).then(response => formatResponse(response?.data));

const formatResponse = (response: TotalizersResponse): TotalizersResponse => ({
  success: response?.success,
  errors: response?.errors,
  data: response?.data,
});
