import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

/**
 * Create or edit audiences based by audience_query
 */
export const saveAudience = (query, name, token, expand, description) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    '/audience/putAudience',
    {
      brand: brand.id,
      audience_name: name,
      audience_description: description,
      audience_user: user.id,
      audience_query: query,
      audience_token: token,
      audience_expand: expand,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

export const getDescription = audienceId => {
  const brand = store.getters.selectedBrand;

  return axios.get('/audiences/get_description', {
    params: {
      brand: brand.id,
      audience_id: audienceId,
    },
  });
};

/**
 * Create or edit audiences based by audience_query
 */
export const renameAudience = (id, name, description) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  axios.post(
    '/audience/putAudience',
    {
      audience_id: id,
      audience_name: name,
      audience_description: description,
      brand: brand.id,
      audience_user: user.id,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

/**
 * Delete Audience
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-put_audience
 */
export const deleteAudience = audienceId => {
  const { user } = store.getters;
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    '/audience/putAudience',
    {
      audience_id: audienceId,
      brand: brand.id,
      audience_enabled: 0,
      audience_user: user.id,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

/**
 * Retrieves the conditions groups to generate audience query
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_query
 */
export const getQuery = id => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  return axios.get('/audiences/get_query', {
    params: {
      brand: brand.id,
      audience_user: user.id,
      audience_id: id,
    },
  });
};

export const previewAudience = (audience, columns, orderby, limit) => {
  const brand = store.getters.selectedBrand;
  const { expInvalidEmail, expInvalidCellphone, expInvalidPhone } = store.getters;
  if (!audience.id) return;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/audience/export`,
    params: {
      aid: audience?.id,
      brand: brand.id,
      columns: columns ? columns.toString() : '',
      preview: 1,
      orderby,
      limit,
      INVALID_EMAIL: expInvalidEmail,
      INVALID_MOBILE_NUMBER: expInvalidCellphone,
      INVALID_PHONE_NUMBER: expInvalidPhone,
    },
  });
};

export const exportAudience = (audience, columns) => {
  const tokenV3 = store.state.auth.tokenV3;
  const brand = store.getters.selectedBrand;
  const { expInvalidEmail, expInvalidCellphone, expInvalidPhone } = store.getters;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/audience/export`,
    params: {
      aid: audience.id,
      brand: brand.id,
      columns: columns ? columns.toString() : '',
      format: 'csv',
      INVALID_EMAIL: expInvalidEmail,
      INVALID_MOBILE_NUMBER: expInvalidCellphone,
      INVALID_PHONE_NUMBER: expInvalidPhone,
    },
  });
};

export const getExportJobDetails = batchId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/audience/getExportJobDetails`,
    params: {
      batchId,
      brand: brand.id,
    },
  });
};

export const cancelExportImportJob = batchId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `/audience/cancelJob`,
    params: {
      batchId,
      brand: brand.id,
    },
  });
};

/**
 * Export audience to external service
 * @param {number} audience The audience ID to be exported
 * @param {string} service The connector name to export the audienc { Responsys | Generic | Facebook | Google }
 * @param {number} interval The interval in hours to schedule an automated export
 * @param {string} startDate The starting datetime to run a scheduled export
 */
export const connectAudience = ({
  audience,
  service,
  interval = 0,
  startDate = '',
  instance = '',
  typeSendCampaign,
  config = [],
} = {}) => {
  const brand = store.getters.selectedBrand;
  // the configuration parameters (expInvalidEmail...) are not used by Responsys
  const { expInvalidEmail, expInvalidCellphone, expInvalidPhone } = store.getters;
  return axios.get('/audiences/export', {
    params: {
      aid: audience,
      brand: brand.id,
      connect: service,
      interval,
      instance,
      ...(service === 'omnichat' && { export: typeSendCampaign }),
      config,
      ...(startDate && { starting_date: startDate }),
      ...(service !== 'Responsys' && { INVALID_EMAIL: expInvalidEmail }),
      ...(service !== 'Responsys' && {
        INVALID_MOBILE_NUMBER: expInvalidCellphone,
      }),
      ...(service !== 'Responsys' && { INVALID_PHONE_NUMBER: expInvalidPhone }),
    },
  });
};

/**
 * Get exported columns list
 * @params #
 */
export const getExportationColumns = () => {
  const user = store.getters.user;
  const brand = store.getters.selectedBrand;
  return axios.get('/audiences/get_export_columns_list', {
    params: {
      brand: brand.id,
      locale: user?.language || 'pt-BR',
    },
  });
};

/**
 * Import new audience
 * @params #
 */
export const submitAudience = (csvfile, audienceName, audienceMatch, audienceSwitch) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  const formData = new FormData();
  formData.append('brand', brand.id);
  formData.append('csvfile', csvfile);
  formData.append('audienceName', audienceName);
  formData.append('audienceMatch', audienceMatch);
  formData.append('audienceSwitch', audienceSwitch);

  return axios.post('audience/import', formData, {
    baseURL: endpointVersion('v3'),
    headers: { 'content-type': 'multipart/form-data', Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

export const getImportJobDetails = batchId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/audience/getImportJobDetails`,
    params: {
      batchId,
      brand: brand.id,
    },
  });
};

/**
 * Get audience import keys
 * @params #
 */
export const getAudienceImportKeys = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('audience/getAudienceImportKeys', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Updates the schedule export data
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-update_schedule
 */
export const updateAudience = ({ scheduleID, connectID, interval, enabled = 1 } = {}) => {
  const brand = store.getters.selectedBrand;
  const params = {
    schedule_id: scheduleID,
    connect: connectID,
    interval,
    enabled,
    brand: brand.id,
  };

  return axios.get('audiences/update_schedule', { params });
};

/**
 * Retrieves a list of logs from Connect export
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_export_logs
 */
export const historic = ({ aid, cid, orderby = '', limit = '', page = '' } = {}) => {
  const brand = store.getters.selectedBrand;

  return axios.get('/audiences/get_export_logs', {
    params: {
      brand: brand.id,
      audience_id: aid,
      connector_id: cid,
      limit,
      page,
      orderby,
    },
  });
};

/**
 * Get results by specified field
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_results_by_field
 */
export const getResultByTerm = (fieldName, userTerm) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/audience/getResultsByField', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      field: fieldName,
      term: userTerm,
      limit: 25,
      orderby: fieldName,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Retrieves a list of Audiences
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_audiences
 */
export const getAudiences = props => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/audience/getAudiences', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      ...props,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Retrieves the distinct PID quantity from a given group of conditions
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_count
 * set to params audienceId if exists or query when create new audience
 */
export const getCount = (query, audienceId, audienceExpand, description) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/audience/getCount', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      ...(audienceId ? { audienceId: audienceId } : { audienceQuery: query }),
      audienceExpand: audienceExpand,
      audienceDescription: description,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

/**
 * Retrieves all available connectors
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_connectors
 */
export const getConnectors = () => {
  const brand = store.getters.selectedBrand;

  return axios.get('/audiences/get_connectors', {
    params: {
      brand: brand.id,
    },
  });
};

/**
 * Create or edit a new Connector and it's credentials. Sensitive data are encrypted.
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-put_connector
 */
export const putConnector = (id, name, alias) => {
  const brand = store.getters.selectedBrand;

  return axios.put('/audiences/put_connector', {
    params: {
      brand: brand.id,
      id,
      name,
      alias,
    },
  });
};

/**
 * Get connectors list by audience
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_connectors_by_audience
 */
export const getConnectorsbyAudience = aid => {
  const brand = store.getters.selectedBrand;

  return axios.get('/audiences/get_connectors_by_audience', {
    params: {
      brand: brand.id,
      audience_id: aid,
    },
  });
};

/**
 * Get list of segmentation columns
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-GetColumns
 */
export const getColumns = () => {
  const brand = store.getters.selectedBrand;

  return axios.get('/audiences/get_columns', {
    params: {
      brand: brand.id,
    },
  });
};

/**
 * Chained list of segmentation columns
 * @params https://labs.pmweb.com.br/docs/context-v3/#api-Audiences-GetColumnsChained
 */
export const getColumnsChained = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const user = store.getters.user;

  return axios.get('/audience/getColumnsChained', {
    baseURL: endpointVersion('v3'),
    params: {
      locale: user?.language || 'pt-BR',
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Get expansion
 * @params https://labs.pmweb.com.br/docs/context/#api-Audiences-get_expansion_lookalike
 */
export const getLookALike = token => {
  const brand = store.getters.selectedBrand;
  return axios.get('/audiences/get_expansion_lookalike', {
    params: {
      brand: brand.id,
      audience_token: token,
    },
  });
};

/**
 * Check if the expansion is active
 * @params https://labs.pmweb.com.br/docs/context/#api-Look-alike-isActive
 */
export const isExpansionActive = () => {
  const brand = store.getters.selectedBrand;
  return axios.get('/lookalike/isActive', {
    params: {
      brand: brand.id,
    },
  });
};
