<template>
  <div>
    <!-- modal principal -->
    <modal-dashed
      modal-type="modal-audiences-importation"
      :is-visible="isModalDashedVisible"
      @closeModalDashed="isModalDashedVisible = $event"
    >
      <div class="file-drag-drop d-flex align-items-center justify-content-center" :class="{ dragging: dragging }">
        <form id="csvfile" ref="fileform" name="csvfile" class="d-flex flex-column align-items-center">
          <div class="header d-flex flex-column align-items-center justify-content-center">
            <img src="@/assets/img/illustrations/meditation.svg" :alt="$t('audiences.importation.title')" />
            <span v-show="!dragging" class="h1 mt-3">{{ $t('audiences.importation.title') }}</span>
          </div>
          <input id="file" type="file" name="file" @change="handleFile" />
          <label v-show="!dragging" for="file" class="drop-files d-flex align-items-center">
            <span class="btn btn-upload">{{ $t('audiences.importation.search') }}</span>
            <span v-show="dragAndDropCapable" class="ml-3">{{ $t('audiences.importation.drag-drop') }}</span>
          </label>
          <p v-show="!dragging" class="mt-3">
            {{ $t('audiences.importation.only-csv') }}
          </p>
          <p v-show="dragging" class="mt-3">
            {{ $t('audiences.importation.drop') }}
          </p>
        </form>
      </div>
      <load-spinner :id="id" color="white" :text="$t('audiences.importation.importing')" />
    </modal-dashed>

    <modal-dashed
      modal-type="modal-audiences-importation"
      :is-visible="isModalDashedErroVisible"
      :is-red="true"
      @closeModalDashed="isModalDashedErroVisible = $event"
    >
      <div class="extension-error d-flex flex-column align-items-center">
        <i class="icon icon-error-60px color-red"></i>
        <span class="title">{{ $t(`audiences.importation.error.${error}`) }}</span>
        <p v-if="error === 'extension'">
          {{ $t('audiences.importation.error.only-csv') }}
        </p>
        <p v-else>{{ $t('audiences.importation.error.too-large') }}</p>
        <button class="btn btn-back" @click.prevent="goBackModalDashedError()">
          <i class="icon icon-back-arrow"></i> {{ $t('back') }}
        </button>
      </div>
    </modal-dashed>

    <audience-importation-options :id="`${id}-options`" />

    <modal-process-progress
      :is-close-modal="isCloseProgressModal"
      :progress-bar="progress"
      :mode="'importation'"
      :id="'modal-process-progress-importation'"
      :batch-id="batchId"
      @update:isCloseModal="val => (isCloseProgressModal = val)"
    />

    <audience-importation-success />

    <el-dialog v-model="isErrorModalVisible" :id="`error-${id}`" append-to-body>
      <div class="general-error d-flex flex-column align-items-center">
        <i class="icon icon-sad-face color-red"></i>
        <div class="title">{{ $t('audiences.importation.error.title') }}</div>
        <p>
          <span v-html="$t('audiences.importation.error.try-again')"></span><a :href="mailToOtoSupport"> {{ link }}</a>
        </p>
        <button class="btn btn-back" @click.prevent="goBackErrorModal()">
          <i class="icon icon-back-arrow"></i> {{ $t('back') }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// services
import { submitAudience, getImportJobDetails } from '@/services/audiences';

// helpers
import bus from '@/helpers/events/bus';

// utilities
import { otoSupportEmail } from '@/utilities/constants';

// components
import ModalDashed from '@/components/_atoms/ModalDashed';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import AudienceImportationSuccess from '@/components/audiences/importation/AudienceImportationSuccess';
import AudienceImportationOptions from '@/components/audiences/importation/AudienceImportationOptions';
import ModalProcessProgress from '@/components/audiences/processProgress/ModalProcessProgress';

export default {
  components: {
    ModalDashed,
    LoadSpinner,
    AudienceImportationSuccess,
    AudienceImportationOptions,
    ModalProcessProgress,
  },
  data() {
    return {
      id: 'audiences-importation',
      ready: false,
      file: undefined,
      dragAndDropCapable: false,
      dragging: false,
      importationResult: {},
      error: undefined,
      isCloseProgressModal: false,
      progress: 0,
      batchId: '',
      link: otoSupportEmail,
      mailToOtoSupport: `mailto:${otoSupportEmail}`,
      isErrorModalVisible: false,
      isModalDashedVisible: false,
      isModalDashedErroVisible: false,
    };
  },

  mounted() {
    this.dragAndDrop();
    bus.$on('open-importation', () => {
      this.reset();
      this.openModal();
    });
  },

  beforeMount() {
    bus.$off(['open-importation']);
  },

  methods: {
    reset() {
      this.progress = 0;
      this.dragging = false;
      this.file = undefined;
      this.ready = false;
      this.error = undefined;
      if (this.$refs.fileform) this.$refs.fileform.reset();
    },

    openModal() {
      this.isModalDashedVisible = true;
    },

    closeModal() {
      this.isModalDashedVisible = false;
    },

    goBackModalDashedError() {
      this.isModalDashedErroVisible = false;
      bus.$emit('open-importation');
    },

    goBackErrorModal() {
      this.isErrorModalVisible = false;
      bus.$emit('open-importation');
    },

    openModalError() {
      this.progress = 0;
      this.isCloseProgressModal = true;
      this.isErrorModalVisible = true;
    },

    initUploadFile(id, audienceName, match, audienceID) {
      bus.$emit('show-spinner', id);
      document.querySelector('body').classList.add('no-click');
      submitAudience(this.file, audienceName, match, audienceID).then(({ data }) => {
        this.batchId = data?.data?.batchId;
        if (data.success && this.batchId) {
          this.uploadFile(this.batchId, id);
        } else {
          this.hideSpinner(id);
          this.openModalError();
        }
      });
    },

    uploadFile(batchId, id) {
      this.isCloseProgressModal = false;
      const maxPercent = 100;
      if (!this.batchId) return;

      getImportJobDetails(this.batchId)
        .then(({ data }) => {
          const result = data?.data?.result;

          const errorCodes = ['job-cancelled-with-errors', 'clickhouse-insert-fail'];
          const cancelledWithErrors = errorCodes.includes(result?.code);

          const cancelledByUser = result?.code === 'job-cancelled-by-user';

          this.progress = data?.data?.result?.progress;

          this.hideSpinner(id);

          if (!result || cancelledWithErrors) return this.openModalError();
          else if (cancelledByUser) return this.cancelExportationFetch();
          else if (this.progress < maxPercent) setTimeout(() => this.uploadFile(batchId), 3000);
          else setTimeout(() => this.importationSuccess(data), 1000);
        })
        .catch(() => {
          this.isCloseProgressModal = true;
          this.hideSpinner(id);
          this.openModalError();
        });
    },

    importationSuccess(data) {
      this.importationResult = data?.data?.result;
      bus.$emit('importation-success', this.importationResult);
      this.isCloseProgressModal = true;
    },

    cancelExportationFetch() {
      this.isCloseProgressModal = true;
      this.progress = 0;
    },

    hideSpinner(id) {
      bus.$emit('hide-spinner', id);
      document.querySelector('body').classList.remove('no-click');
    },

    checkDragCapable() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },

    checkExtension(fileName) {
      const extension = fileName.split('.');
      return extension[1] === 'csv';
    },

    checkSize(fileSize) {
      // valida tamanho do arquivo. Limite 25MB
      const sizetoKb = fileSize / 1000;
      return sizetoKb <= 25000;
    },

    handleFile(event) {
      this.submit(event.target.files[0]);
    },

    submit(file) {
      bus.$emit('show-spinner', this.id);
      setTimeout(() => {
        bus.$emit('hide-spinner', this.id);
        this.closeModal();
        if (!this.checkExtension(file.name)) {
          this.error = 'extension';
          setTimeout(() => (this.isModalDashedErroVisible = true), 300);
        } else if (!this.checkSize(file.size)) {
          this.error = 'size';
          this.isModalDashedErroVisible = true;
        } else {
          this.file = file;
          setTimeout(() => bus.$emit('open-importation-options'), 300);
        }
      }, 600);
    },
    dragAndDrop() {
      this.dragAndDropCapable = this.checkDragCapable();
      // verifica se a funcionalidade de arrastar e soltar é permitida
      if (this.dragAndDropCapable) {
        // itera e vincula ao form os eventos relacionados ao arrasto
        ['dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
          window.addEventListener(
            evt,
            e => {
              // valida se os elementos arrastados sao arquivos
              if (e.dataTransfer.types.includes('Files')) {
                e.preventDefault();
                e.stopPropagation();
                if (evt === 'dragover' || evt === 'dragenter') {
                  bus.$emit('open-importation');
                  this.dragging = true;
                } else if (evt === 'dragleave' || evt === 'drop') this.dragging = false;

                if (evt === 'drop') this.submit(e.dataTransfer.files[0]);
              }
            },
            false,
          );
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#file {
  display: none;
}
.file-drag-drop {
  height: 100%;
  width: 100%;
}
#csvfile {
  * {
    pointer-events: none;
  }
  .btn-upload {
    width: 200px;
    pointer-events: auto;
    padding: 0 15px;
  }
}
.btn-back {
  width: 130px;
  position: relative;
  .icon {
    position: absolute;
    left: 5px;
    top: 4px;
  }
}
.extension-error {
  margin-top: 150px;
  .btn-back {
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
.extension-error,
.general-error {
  .title {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: $gray-800;
    margin-bottom: 10px;
  }
}
.icon-error-60px,
.icon-sad-face {
  font-size: 60px;
  margin-bottom: 20px;
}
</style>

<style lang="scss">
body.no-click {
  pointer-events: none;
  #importation-option .close-modal {
    display: none;
  }
}
#audiences-importation-pre-error,
#audiences-importation-options {
  .modal-content {
    max-width: 540px;
    height: 540px;
    .modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
#audiences-importation {
  .modal-content {
    max-width: 540px;
    height: 540px;
    pointer-events: none;
    .modal-header {
      pointer-events: auto;
    }
  }
}
#audiences-importation-options {
  .modal-content {
    max-width: 540px;
    .modal-body {
      padding-top: 0;
      p {
        font-size: 12px;
        font-weight: normal;
        a {
          color: $gray-700;
        }
      }
      .btn-back {
        margin-top: 35px;
        margin-bottom: 20px;
      }
    }
  }
}
#success-audiences-importation,
#importation-option {
  .modal-dialog .modal-content {
    max-width: 540px;
    .modal-body {
      padding-top: 0;
    }
  }
}

#error-audiences-importation {
  max-width: 540px;
  text-align: center;

  .el-dialog__body {
    padding-top: 0;
    p {
      font-size: 12px;
      font-weight: normal;
      a {
        color: $gray-700;
      }
    }
    .btn-back {
      margin-top: 35px;
      margin-bottom: 20px;
    }
  }
}

.modal-audiences-importation {
  .el-dialog__close {
    top: 18px !important;
  }
}
</style>
