<template>
  <div class="empty">
    <img :src="require('@/assets/img/icons/welcome/bell.svg')" :alt="$t('welcome.data-ingestion.alt-bell')" />
    <h3>{{ $t('welcome.data-ingestion.empty-title') }}</h3>
    <p>{{ $t('welcome.data-ingestion.empty-text') }}</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.empty {
  text-align: center;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0 2px 5px 0 #0000000d;

  display: grid;
  place-content: center;
  justify-items: center;

  p {
    width: 300px;
  }

  img {
    margin-bottom: 8px;
  }
}
</style>
