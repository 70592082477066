<template>
  <section class="sellers-table">
    <div class="row">
      <div class="sellers-table__container">
        <base-card-v2 class="sellers-table__list">
          <template v-if="!loading">
            <base-card-header :title="$tc('360.sellers.title')" svg="User">
              <template #item-right>
                <div class="sellers-table__actions">
                  <!-- inactives -->
                  <div class="sellers-table__show-inactives">
                    <label @click="toggleShowInactives" data-cy="show-inactives">
                      <check-box :checked="showInactives" />
                      <span>{{ $t('360.sellers.show-inactive') }}</span>
                    </label>
                  </div>

                  <!-- stores -->
                  <el-select-v2
                    v-model="selectedStore"
                    :options="[
                      { value: '', label: this.$t('360.sellers.select-default.all') },
                      ...formattedOrderedStores,
                    ]"
                    :empty-values="[null, undefined]"
                    :value-on-clear="null"
                    @clear="clearSelectedStore"
                    :clearable="!!selectedStore"
                    filterable
                    style="width: 170px"
                    class="sellers-select"
                    popper-class="sellers-table-dropdown"
                  >
                    <template #default="{ item }">
                      <span>{{ item.label }}</span>
                    </template>
                    <template #empty>
                      {{ $t('360.sellers-analytics.select-empty') }}
                    </template>
                  </el-select-v2>

                  <!-- search -->
                  <div class="sellers-table__search-and-filter">
                    <input
                      ref="inputTerm"
                      v-model="term"
                      class="findTerm"
                      data-cy="search-seller"
                      :placeholder="$t('360.sellers.search-sellers')"
                    />
                    <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                    <i v-else class="icon icon-search"></i>
                  </div>
                  <button
                    v-if="loginWithPin"
                    class="btn sellers-table__btn-add"
                    data-cy="btn-add-seller"
                    :title="$t('360.sellers.add-seller')"
                    @click.prevent="addSeller"
                  >
                    {{ $t('360.sellers.add-seller') }}
                  </button>
                </div>
              </template>
            </base-card-header>
            <template v-if="sellersList.length">
              <div class="tg-list">
                <div class="tg-row -header">
                  <div class="tg-col">
                    <div class="header-title">
                      {{ $t('360.sellers.columns.name') }}
                    </div>
                    <div :class="['order-by', { active: /name/.test(orderBy) }]" @click.prevent="ordenate('name')">
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.customers') }}
                    <div
                      :class="['order-by', { active: /customers/.test(orderBy) }]"
                      @click.prevent="ordenate('customers')"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.enrollment') }}
                    <div
                      :class="['order-by', { active: /enrolment/.test(orderBy) }]"
                      @click.prevent="ordenate('enrolment')"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.storeName') }}
                    <div
                      :class="['order-by', { active: /storeName/.test(orderBy) }]"
                      @click.prevent="ordenate('storeName')"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.store') }}
                    <div :class="['order-by', { active: orderBy === 'store' }]" @click.prevent="ordenate('store')">
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.status') }}
                    <div :class="['order-by', { active: /status/.test(orderBy) }]" @click.prevent="ordenate('status')">
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                </div>

                <div
                  v-for="(seller, index) in sellersList"
                  :key="index"
                  :class="['tg-row', { 'refreshing-list': loading }]"
                  :data-cy="`table-sellers-${index}`"
                  @click.self="openSeller(seller.id)"
                >
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span class="sellers-table__customer-icon">
                      <i class="icon icon-customer"></i>
                    </span>
                    <span :id="'seller' + seller.id" ref="persona_list_item" class="persona-name ellipsis">
                      {{ seller.name }}
                      <span v-if="seller.status === 'inactive'" class="sellers-table__inactive"
                        >({{ $t('360.sellers.inactive') }})</span
                      >
                    </span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{
                      $_getValue({
                        type: 'integer',
                        amount: seller.customers,
                      })
                    }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{ seller.enrolment }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{ seller.storeName }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{ seller.store }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>
                      <span class="bol" :class="`${seller.status}`"></span>
                      {{ $t(`360.sellers.${seller.status}`) }}
                    </span>
                  </div>
                </div>

                <div v-if="!loading && pagination.count === 0" class="sellers-table__no-results">
                  {{ $t('360.sellers.not-found') }}
                </div>
              </div>
            </template>
          </template>
          <base-loader v-show="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </base-card-v2>
      </div>
    </div>

    <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="[10, 20, 50]" :identify="id" />
    <delete-persona />
    <seller-detail :seller-id="$route.params.sellerId || 0" />
    <question-modal />
  </section>
</template>

<script>
// libs
import { mapActions, mapGetters } from 'vuex';
import { isEmpty, debounce } from 'lodash';
import { useToast } from 'vue-toastification';
// services
import { getSellers } from '@/services/oto/sellers';
// helpers
import bus from '@/helpers/events/bus';
import { tableMixin, verifyMixin } from '@/helpers/mixins';
// atoms
import CheckBox from '@/components/_atoms/CheckBox';
import ListPagination from '@/components/_atoms/ListPagination';
import InfoCard from '@/components/_atoms/InfoCard.vue';
// components
import DeletePersona from '@/components/personas/_organisms/DeletePersona';
import QuestionModal from '@/components/oto/sellers/QuestionModal';
import SellerDetail from '@/components/oto/sellers/SellerDetail';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// store
import store from '@/store';

export default {
  name: 'SellersTable',
  components: {
    CheckBox,
    BaseLoader,
    DeletePersona,
    SellerDetail,
    ListPagination,
    QuestionModal,
    InfoCard,
  },
  mixins: [tableMixin, verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'sellers-list',
      showInactives: false,
      term: '',
      order: null,
      isActive: '',
      tableData: null,
      selectedStore: '',
      storesList: null,
      sellersList: [],
      hasSellersWarning: false,
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      orderBy: 'name',
    };
  },
  computed: {
    ...mapGetters('sellers', ['getOrderedStores']),
    loginWithPin() {
      return !store.getters.hasSso;
    },
    formattedOrderedStores() {
      return this.getOrderedStores.map(({ id, name }) => ({
        value: id,
        label: id ? `${name} - ${id}` : name,
      }));
    },
  },
  watch: {
    selectedStore: function () {
      this.pagination.page = 1;
      this.fetchSellers();
    },
    term: debounce(function () {
      this.pagination.page = 1;
      this.fetchSellers();
    }, 800),
  },
  mounted() {
    // load vuex lists
    this.fetchSellers();
    this.getStoresList();
    this.getActiveSellersList();

    if (this.$route.params.sellerId) {
      bus.$emit('seller-detail-modal');
    }

    bus.$on('render-cards', () => {
      this.fetchSellers();
      this.getStoresList();
    });
    bus.$on('sellers-reload-list', () => {
      this.fetchSellers();
      this.getStoresList();
    });
    bus.$on(`${this.id}-change-page`, () => {
      this.fetchSellers();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('sellers-reload-list');
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    ...mapActions('sellers', ['getStoresList', 'getActiveSellersList', 'setLoadingTab']),
    clearSelectedStore() {
      this.selectedStore = '';
    },
    fetchSellers() {
      this.$_reqConfig();

      const filter = {
        storeId:
          typeof this.selectedStore === 'string' || this.selectedStore === null
            ? this.selectedStore
            : this.selectedStore[0].id,
      };

      const args = {
        ...(!this.showInactives && { enabled: 1 }),
        ...(!isEmpty(this.term) && { term: this.term }),
        orderBy: this.orderBy,
        page: this.pagination.page,
        limit: this.pagination.limit,
      };

      if (filter.storeId) args.filter = `storeId:${filter.storeId}`;

      getSellers(args.limit, args.page, args)
        .then(data => {
          if (!data) {
            this.hasError = true;
            this.$_componentHeight();
            this.setLoadingTab(false);
            return;
          }

          if (this.$_verifyData(data?.data)) {
            this.sellersList = [];
            this.setLoadingTab(false);
            return;
          }

          this.sellersList = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                return [data.series[i].key, val];
              }),
            );
          });

          this.hasSellersWarning = data?.hasSellersWarning;
          bus.$emit('has-sellers-warning', data?.hasSellersWarning);

          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ordenate(order) {
      this.orderBy = this.orderBy === order ? `-${order}` : order;
      this.fetchSellers();
    },
    addSeller() {
      this.$router.push({
        name: '360-sellers-detail',
        params: { sellerId: 'create' },
      });
    },
    /**
     * @description clear term of search input
     */
    clearTerm() {
      this.term = '';
    },
    /**
     * @description change route to detailed label page
     * @param {Number} id
     */
    openSeller(id) {
      this.$router.push({
        name: '360-sellers-detail',
        params: { sellerId: id },
      });
    },
    toggleShowInactives() {
      this.pagination.page = 1;
      this.showInactives = !this.showInactives;
      this.fetchSellers();
    },
  },
};
</script>

<style lang="scss" scoped>
.tg-list {
  min-height: 300px;
  font-size: 12.1px;
  color: #888;
  .tg-row {
    display: grid;
    grid-template-columns: 35% 10% 13% 19% 14% 10%;
    grid-template-rows: 40px;
    border-bottom: 1px solid #eee;
    justify-items: start;
    align-items: center;

    &.-header {
      font-weight: 600;

      .order-by {
        background-color: $gray-400;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        line-height: 100%;
        padding: 3px 0;
        margin-left: 5px;
        height: 15px;
        width: 15px;
        min-width: 18px;
        text-align: center;

        .dash-arrows {
          display: block;
          color: $color-white;
          border: solid $gray-900;
          border-width: 0 1px 1px 0;
          border-radius: 0;
          display: inline-block;
          padding: 3px;
          transform: translate(0, -50%) rotate(45deg);
        }

        &.active {
          background-color: $oto-omni;

          .dash-arrows {
            border-color: $color-white;
          }
          .asc {
            transform: translate(0, -50%) rotate(45deg);
          }
          .desc {
            transform: rotate(-135deg);
          }
        }
      }
      .tg-col:first-child {
        padding-left: 20px;
      }
    }
    &:not(.-header) {
      cursor: pointer;
      &:nth-child(even) {
        background: #eeeeee4d;
      }
      &:hover {
        background: #eeeeee;
      }
      .tg-col:first-child {
        padding-left: 15px;
      }
    }

    &.refreshing-list {
      opacity: 0.5;
    }
  }
}

.tg-col {
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    z-index: 1;

    .bol {
      border-radius: 50%;
      display: inline-block;
      height: 9px;
      width: 9px;
      margin-right: 10px;
    }

    .active {
      background: #35ba9b;
    }

    .inactive {
      background: $gray-500;
    }

    .deleted {
      background: $pink-500;
    }

    .locked,
    .pending {
      background: $yellow-400;
    }

    &.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.persona-name {
      color: $oto-omni;
      user-select: text;
      max-width: 300px;
      &:focus {
        max-width: 100%;
        min-width: 150px;
        outline: 0 none;
        border-bottom: 1px solid;
        background: #fbfbfb;
        z-index: 2;
      }
    }

    &.round-name {
      border: 1px solid #bbb;
      padding: 6px;
      border-radius: 100%;
      margin-right: 12px;
      width: 31px;
      height: 31px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  svg {
    margin-right: 8px;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/vendors/_v-select';

.sellers-select {
  .el-select {
    &__wrapper {
      border-radius: 25px;
      border: 1px solid $gray-500;
      box-shadow: none !important;

      &.is-focused {
        border-color: $oto-omni !important;
      }
    }

    &__selection {
      font-size: 12px;
      font-weight: 600;
      color: $gray-800;
    }
  }
}

.sellers-table-dropdown {
  .el-select-dropdown {
    width: 350px !important;

    &__item {
      font-size: 12px;
      font-weight: 500;
      color: $gray-800;
    }

    .el-vl {
      &__wrapper,
      &__window {
        width: 100% !important;
      }
    }
  }
}

.sellers-table {
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  &__container {
    align-self: center;
    flex: 0 0 100%;
    max-width: 100%;
  }

  &__list {
    min-height: 557px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    gap: 15px;
  }

  &__show-inactives {
    display: flex;

    label {
      display: flex;
      align-items: center;
      > span {
        margin-left: 5px;
      }
    }
  }

  &__inactive {
    color: #bbb;
  }

  &__select-store {
    width: 170px;
    margin-right: 15px;
  }

  &__search-and-filter {
    width: 170px;
    position: relative;
    .findTerm {
      width: 100%;
    }
    i {
      position: absolute;
      right: 8px;
      top: 4px;
      font-size: 26px;
      color: #bbb;
      cursor: pointer;
    }
  }

  &__customer-icon {
    margin-right: 10px;
    .icon {
      font-size: 23px;
      line-height: inherit;
    }
  }

  &__no-results {
    text-align: center;
    margin-top: 80px;
  }

  &__btn-add {
    width: 170px;
    height: 34px;
    display: block !important;
    line-height: 34px !important;
  }
}
</style>
