import { getActionList } from '../services';
import { ResponseDataFetchActions, ParamsFetchAction, Action } from '../models';
import store from '@/store';

export const fetchActionsList = (params: ParamsFetchAction) =>
  getActionList(params).then(response => formattedData(response.data));

const formattedData = (response: ResponseDataFetchActions) => {
  const isPerformanceEnabled = store?.getters?.getEnabledActionPerformance;

  const columns = response?.series
    ?.filter(serie => {
      return isPerformanceEnabled || serie.key !== 'performance';
    })
    .map(serie => ({ label: serie.name, key: serie.key }));

  const data = response?.data.map(remoteAction => {
    const formattedData: Action = {
      action: remoteAction.action,
      campaignsApproval: remoteAction.campaignsApproval,
      createDate: remoteAction.createDate,
      enabled: remoteAction.enabled,
      enabledApproval: remoteAction.enabledApproval,
      id: remoteAction.id,
      isActivable: remoteAction.isActivable,
      period: remoteAction.period,
      priority: remoteAction.priority,
      actionCategory: remoteAction.actionCategory,
    };

    if (isPerformanceEnabled) {
      formattedData.performance = remoteAction.performance;
    }

    return formattedData;
  });

  return {
    data,
    errors: response.errors,
    columns,
    totalCount: response?.totalCount,
    success: response?.success,
  };
};
