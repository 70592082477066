<template>
  <div class="customers-table">
    <div class="customers-table__empty-state" v-show="!$parent.hasData">
      <empty-state
        :title="$t('360.customers.empty-state-customer.title')"
        :text="$t('360.customers.empty-state-customer.text')"
        :has-button="false"
      >
        <template v-slot:image>
          <img src="@/assets/img/oto/emoji/large/oto_emoji_large_maintenance.svg" alt="" />
        </template>
      </empty-state>
    </div>

    <table-list v-if="$parent.hasData && !loading && !$parent.hasError" :id="id" :data="dataTable" :type="type">
      <table-list-header>
        <table-list-column class-name="col-xl-6">
          {{ $tc('360.customer', 1) }}
        </table-list-column>
        <table-list-column class-name="col-xl-2 text-right">
          {{ $t('360.last-purchase') }}
        </table-list-column>
        <table-list-column class-name="col-xl-2 text-right">
          {{ $t('360.avg-ticket') }}
        </table-list-column>
        <table-list-column class-name="col-xl-2 text-right">
          {{ $t('360.revenue') }}
        </table-list-column>
      </table-list-header>
      <table-list-body class="table-body" :class="{ loadHeight: loading }">
        <table-list-row
          v-for="customer in dataTable.rows"
          :key="customer.id"
          :click-callback="() => openProfile(customer.id)"
          style="cursor: pointer"
        >
          <table-list-column class-name="customer-name col-xl-6">
            {{ customer.name }}
          </table-list-column>
          <table-list-column class-name="col-xl-2 text-right">
            {{ $_formatDate(customer.lastOrder, 'DD MMM YYYY') }}
          </table-list-column>
          <table-list-column class-name="col-xl-2 text-right">
            {{ formatCurrency(customer.avgOrder) }}
          </table-list-column>
          <table-list-column class-name="col-xl-2 text-right">
            {{ formatCurrency(customer.revenue) }}
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </table-list>

    <base-loader v-if="loading" class="card" />
    <info-card v-if="$parent.hasError" ref="message-error" class="card" :error="$parent.hasError" />
  </div>
</template>

<script>
/* eslint-disable no-param-reassign, no-return-assign */
import bus from '@/helpers/events/bus';
import { numberMixin, dateMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import { getCustomersTable } from '@/services/oto/customers';
import { dateCompleteVariation } from '@/utilities/constants';
import InfoCard from '@/components/_atoms/InfoCard';
import EmptyState from '@/components/_atoms/EmptyState.vue';

export default {
  name: 'CustomersList',
  components: {
    EmptyState,
    BaseLoader,
    InfoCard,
  },
  mixins: [numberMixin, dateMixin],
  props: {
    term: {
      type: String,
      default: '',
      required: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: 'customers-list',
      loading: false,
      dataTable: {
        count: 0,
        limit: 10,
        page: 1,
        rows: [],
      },
      type: 'filled',
      dateCompleteVariation,
    };
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetchData();
      this.listeners();
    });

    this.fetchData();
    this.listeners();
  },
  beforeUnmount() {
    this.listeners(true);

    bus.$off('render-cards');
  },
  methods: {
    formatCustomers(data) {
      function CUSTOMER(customer) {
        [this.id, this.name, this.lastOrder, this.avgOrder, this.revenue] = customer;
      }

      this.dataTable.rows = [];
      for (let i = 0; i < data.length; i += 1) {
        this.dataTable.rows.push(new CUSTOMER(data[i]));
      }
    },
    fetchData() {
      this.$parent.hasData = true;
      this.$parent.hasError = false;
      this.loading = true;

      const hasFilters = this.filters && this.filters.length;

      // removing id from object, just passing what is needed to the endpoint
      const modifiedFilter = this.filters?.map(filter => ({
        column: filter.column,
        operator: filter.operator,
        value: filter.value,
        referenceObjects: filter.referenceObjects,
      }));

      const filter = hasFilters ? JSON.stringify(modifiedFilter) : '';
      const columns = 'id,customer,last_order,avg_value,revenue';
      const { limit, page } = this.dataTable;
      getCustomersTable(this.$route.query.t, filter, columns, limit, page)
        .then(res => {
          this.dataTable.count = res.total_rows;
          this.$parent.hasData = !!this.dataTable.count;
          this.formatCustomers(res.data);
        })
        .catch(() => {
          this.dataTable.count = 0;
          this.dataTable.rows = [];
          this.$parent.hasError = true;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          this.$parent.firstRequestLoaded = true;
        });
    },
    openProfile(id) {
      const resolvedProfileRoute = this.$router.resolve({
        name: '360-customerviewId',
        params: {
          id,
          brand: this.$store.getters.selectedBrand.id,
        },
      });

      window.open(resolvedProfileRoute.href, '_blank');
    },
    listeners(off = false) {
      const action = off ? '$off' : '$on';

      bus[action]('refresh-search', this.fetchData);
      bus[action](`${this.id}-change-page`, this.fetchData);
      bus[action](`${this.id}-change-order`, this.fetchData);
    },
  },
};
</script>

<style lang="scss" scoped>
.customers-table {
  position: relative;
  min-height: 100px;
  border-radius: 8px;

  .card {
    height: 500px;
    position: relative;
  }

  &__empty-state {
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 24px 60px 0 #00000012;
    padding-bottom: 100px;

    display: grid;
  }
}

.customer-name {
  .name {
    -webkit-user-select: text;
    user-select: text;
    padding: 5px 0;
    display: inline-block;
    margin-right: 10px;
    &:focus {
      min-width: 150px;
      outline: 0 none;
      border-bottom: 1px solid;
    }
  }
}

.table-body {
  &.loadHeight {
    height: 500px;
  }
}
</style>
