<template>
  <div class="suggestion-details-messages">
    <div class="sdc__header">
      <h3 class="m-0">{{ $t('360.campaigns.messages-actions.title') }}</h3>
    </div>

    <template v-if="getLoadingActionTemplates">
      <div class="sdc__table customScrollBar">
        <div class="table">
          <div class="table__header">
            <div class="table__row">
              <div class="table__column">
                <div class="table__header-title">{{ $t('_message', 2) }}</div>
              </div>
              <div class="table__column">
                <div class="table__header-title">{{ $t('mean-of-contact') }}</div>
              </div>
              <div class="table__column text-right">
                <div class="table__header-title">{{ $t('creation-date') }}</div>
              </div>
            </div>
          </div>

          <div class="table__body">
            <div class="table__row table__row--first" style="align-items: flex-start">
              <div :class="['table__column text-left']">
                <el-skeleton :loading="true" animated>
                  <template #template>
                    <el-skeleton-item
                      variant="p"
                      style="height: 10px; width: 180px; display: block; margin-bottom: 8px"
                    />
                    <el-skeleton-item variant="p" style="height: 10px; width: 180px; display: block" />
                  </template>
                </el-skeleton>
              </div>
              <div :class="['table__column text-left']">
                <el-skeleton :loading="true" animated>
                  <template #template>
                    <el-skeleton-item variant="p" style="height: 10px; width: 80px; margin-top: 8px" />
                  </template>
                </el-skeleton>
              </div>
              <div :class="['table__column']">
                <el-skeleton :loading="true" animated>
                  <template #template>
                    <el-skeleton-item variant="p" style="height: 10px; width: 80px; margin-top: 8px" />
                  </template>
                </el-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="hasTemplates && !getLoadingActionTemplates">
      <div class="sdc__table customScrollBar">
        <div class="table">
          <div class="table__header">
            <div class="table__row">
              <div class="table__column">
                <div class="table__header-title">{{ $t('_message', 2) }}</div>
              </div>
              <div class="table__column">
                <div class="table__header-title">{{ $t('mean-of-contact') }}</div>
              </div>
              <div class="table__column text-right">
                <div class="table__header-title">{{ $t('creation-date') }}</div>
              </div>
            </div>
          </div>

          <div class="table__body">
            <div v-for="(item, index) in actionTemplatesList" :key="index" class="table__row">
              <div :class="['table__column text-left']">
                <el-popover effect="dark" trigger="hover" placement="top" :width="300">
                  <p v-html="item.text.replaceAll('\n', '<br />')"></p>
                  <template #reference>
                    <span>{{ item.text }}</span>
                  </template>
                </el-popover>
              </div>

              <div :title="item.channel" :class="['table__column text-left']">
                <span> {{ $t(`360.${item.channel}`) }}</span>
              </div>

              <div class="table__column">
                <span> {{ $_formatDate(item.createdAt) }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import '@/plugins/tooltip/tooltip';
import { actionsMixin } from '@/helpers/mixins';

export default {
  name: 'CampaignsManagementDetailsMessages',
  mixins: [actionsMixin],
  computed: {
    ...mapGetters('campaignsManagement', ['getActionTemplates', 'getLoadingActionTemplates']),

    actionTemplatesList() {
      return this.getActionTemplates;
    },
    hasTemplates() {
      return this.actionTemplatesList?.length;
    },
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateCampaigns']),
  },
};
</script>

<style lang="scss" scoped>
.suggestion-details-messages {
  margin: 40px 0;

  .el-tooltip__trigger {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .sdc__table {
    overflow-y: scroll;
    padding-top: 5px;
  }

  .table {
    font-size: 12px;
    margin: 0;

    .table__header,
    .table__body {
      .table__row {
        color: $gray-800;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        justify-items: start;
        align-items: center;
      }
      .tooltip-overview {
        cursor: pointer;
      }
    }

    .table__header {
      font-weight: 600;
      .table__row {
        border-bottom: 1px solid $gray-300;
      }
    }

    .table__body {
      .table__row {
        grid-auto-columns: 80px;
        border-bottom: 1px solid $gray-400;

        &:last-child {
          border-bottom: none;
        }

        .table__column {
          padding-right: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 95%;

          &:first-child {
            padding: 8px 0;
          }

          span {
            .bol {
              border-radius: 50%;
              display: inline-block;
              height: 9px;
              width: 9px;
              margin-right: 10px;
            }

            .status-0 {
              background: $gray-500;
            }

            .status-1 {
              background: #35ba9b;
            }

            .status-2 {
              background: $pink-500;
            }
          }
        }
      }
    }

    .table__header-title {
      display: inline-block;
      width: fit-content;
      white-space: initial;
      margin-right: 5px;
    }

    .table__order-by {
      background-color: $gray-400;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      line-height: 100%;
      padding: 3px 0;
      height: 18px;
      width: 18px;
      min-width: 18px;
      text-align: center;

      .table__dash-arrows {
        display: block;
        color: $color-white;
        border: solid $gray-900;
        border-width: 0 1px 1px 0;
        border-radius: 0;
        display: inline-block;
        padding: 3px;
        transform: translate(0, -50%) rotate(45deg);
      }

      &--active {
        background-color: $oto-seller;

        .table__dash-arrows {
          border-color: $color-white;
        }

        .table__dash-arrows {
          &--asc {
            transform: translate(0, -50%) rotate(45deg);
          }
          &--desc {
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .sdc__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .sdc__empty-wrapper {
    display: flex;
    align-items: center;
  }

  button.sdc__add-campaign {
    font-weight: normal;
    text-decoration: underline;
  }
}
.sdc__tooltip {
  cursor: pointer;
}
.v-popper--theme-tooltip .v-popper__inner {
  .content {
    display: block;
    justify-content: center;

    .title {
      padding-bottom: 10px;

      span {
        color: $gray-800;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
</style>
