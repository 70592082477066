import router from '@/router';
import { cloneDeep, escapeRegExp } from 'lodash';
import { stringParser } from '@/helpers/string-parser';
import {
  getActions,
  getAction,
  getActionCampaigns,
  getActionTemplates,
  putAction,
  updateAction,
} from '@/services/actions';

import bus from '@/helpers/events/bus';

const defaultSuggestionDetails = {
  id: '',
  name: '',
  description: '',
  dayOfWeek: ['1', '2', '3', '4', '5', '6', '7'],
  dayOfYear: [],
  startDate: '',
  endDate: '',
  campaigns: [],
  enabledApproval: 0,
  isEnabledApproval: false,
  stores: '',
  storesId: [],
  storeSeller: '',
  actionCategory: null,
  enabled: false,
};

const suggestions = {
  namespaced: true,
  state: {
    activePage: 'composition',
    mode: null,
    suggestionsData: [],
    suggestionsList: [],
    suggestionDetail: cloneDeep(defaultSuggestionDetails),
    showToastSuccess: false,
    term: '',
    actionCategoryList: [],
    tooltipEmptyError: {
      actionCategory: false,
      name: false,
    },
    filterStatus: '',
    loadingSuggestions: false,
    loadingSuggestionsDetail: false,
    loadingActionTemplates: false,
    loadingCampaigns: false,
    disableCampaignsLoading: false,
    limitsSelectOptions: {
      no_limit: 'campaigns.limits.no_limit',
      seller: 'campaigns.limits.seller',
      store: 'campaigns.limits.store',
      brand: 'campaigns.limits.brand',
      store_seller: 'campaigns.limits.store_seller',
    },
    tabSelected: 'active',
    // pagination data
    id: 'actions-attachments',
    pagination: {
      count: 0,
      limit: 10,
      page: 1,
      order: '',
    },
    limitRange: [5, 10, 20, 30, 50],
    isDataUnchanged: true,
    isConfirmEditPriorityModalOpen: false,
    disabledButton: false,
    actionTemplates: [],
  },
  mutations: {
    SET_SELECTED_CATEGORY(state, payload) {
      state.suggestionDetail.actionCategory = payload;
    },
    SET_ACTIVE_PAGE(state, payload) {
      state.activePage = payload;
    },
    SET_PRIORITY_DATA_MODIFIED(state, payload) {
      state.isDataUnchanged = payload;
    },
    SET_OPEN_CONFIRM_EDIT_PRIORITY_MODAL(state, payload) {
      state.isConfirmEditPriorityModalOpen = payload;
    },
    SET_MODE(state, payload) {
      state.mode = payload;
    },
    SET_STATUS_TOOLTIP(state, payload) {
      state.tooltipEmptyError[payload.key] = payload.value;
    },
    SET_ACTION_CATEGORY_LIST(state, payload) {
      state.actionCategoryList = payload;
    },
    SET_SUGGESTIONS(state, data) {
      state.suggestionsList = data;
    },
    SET_ID(state, payload) {
      state.suggestionDetail.id = payload;
    },
    SET_SUGGESTION_NAME(state, payload) {
      state.suggestionDetail.name = payload;
    },
    SET_SUGGESTION_DESCRIPTION(state, payload) {
      state.suggestionDetail.description = payload;
    },
    SET_SUGGESTION_ENABLED_APPROVAL(state) {
      state.suggestionDetail.isEnabledApproval = !state.suggestionDetail.isEnabledApproval;
      state.suggestionDetail.isEnabledApproval === true
        ? (state.suggestionDetail.enabledApproval = 1)
        : (state.suggestionDetail.enabledApproval = 0);
    },
    SET_SUGGESTION_IS_ENABLED_APPROVAL(state, payload) {
      state.suggestionDetail.isEnabledApproval = payload;
      state.suggestionDetail.enabledApproval === true
        ? (state.suggestionDetail.enabledApproval = 1)
        : (state.suggestionDetail.enabledApproval = 0);
    },
    SET_SUGGESTION_STORES(state, payload) {
      state.suggestionDetail.stores = payload;
    },
    SET_SUGGESTION_STORES_ID(state, payload) {
      state.suggestionDetail.storesId = payload;
    },
    SET_SUGGESTION_DAYOFWEEK(state, payload) {
      state.suggestionDetail.dayOfWeek = payload;
    },
    SET_SUGGESTION_DAYOFYEAR(state, payload) {
      state.suggestionDetail.dayOfYear = payload;
    },
    SET_SUGGESTION_STARTDATE(state, payload) {
      state.suggestionDetail.startDate = payload;
    },
    SET_SUGGESTION_ENDDATE(state, payload) {
      state.suggestionDetail.endDate = payload;
    },
    SET_ACTION_STARTEND_DATES(state, payload) {
      state.suggestionDetail.startDate = payload.startDate || '';
      state.suggestionDetail.endDate = payload.endDate || '';
    },
    SET_SUGGESTION_CAMPAIGNS(state, payload) {
      state.suggestionDetail.campaigns = payload;
    },
    SET_SUGGESTION_ACTION_TEMPLATES(state, payload) {
      state.actionTemplates = payload;
    },
    SET_SUGGESTION_DETAILS(state, suggestionDetails) {
      if (state.mode === 'create') return;
      const {
        id,
        name,
        description,
        enabledApproval,
        stores,
        dayOfWeek,
        dayOfYear,
        startDate,
        endDate,
        actionCategory,
        enabled,
      } = suggestionDetails;

      state.suggestionDetail.id = id;
      state.suggestionDetail.name = name;
      state.suggestionDetail.description = description;
      state.suggestionDetail.enabledApproval = Number(enabledApproval);
      state.suggestionDetail.stores = stores;
      state.suggestionDetail.dayOfWeek = dayOfWeek;
      state.suggestionDetail.dayOfYear = dayOfYear;
      state.suggestionDetail.startDate = startDate;
      state.suggestionDetail.endDate = endDate;
      state.suggestionDetail.actionCategory = actionCategory;
      state.suggestionDetail.enabled = enabled;
    },
    SET_SHOW_TOAST_SUCCESS(state, value) {
      state.showToastSuccess = value;
    },
    SET_SEARCH_TERM(state, term) {
      state.term = term;
    },

    SET_FILTER_STATUS(state, value) {
      state.filterStatus = value;
    },

    SET_LOADING_SUGGESTIONS(state, payload) {
      state.loadingSuggestions = payload;
    },

    SET_LOADING_SUGGESTIONS_DETAIL(state, payload) {
      state.loadingSuggestionsDetail = payload;
    },

    SET_LOADING_ACTION_TEMPLATES(state, payload) {
      state.loadingActionTemplates = payload;
    },

    SET_LOADING_CAMPAIGNS(state, payload) {
      state.loadingCampaigns = payload;
    },

    SET_AUDIENCE_CAMPAIGNS(state, payload) {
      if (!state.suggestionDetail.campaigns) state.suggestionDetail.campaigns = [];
      state.suggestionDetail.campaigns.push(payload);
    },

    SET_EDIT_AUDIENCE_CAMPAIGNS(
      state,
      { name, audience, index, limitType, limitData, limitGroupRemaining, sellerAllocation },
    ) {
      state.suggestionDetail.campaigns[index].name = name ? name.trim() : '';
      state.suggestionDetail.campaigns[index].audience = audience;
      state.suggestionDetail.campaigns[index].limitType = limitType;
      state.suggestionDetail.campaigns[index].limitData = limitData;
      state.suggestionDetail.campaigns[index].limitGroupRemaining = limitGroupRemaining;
      state.suggestionDetail.campaigns[index].sellerAllocation = sellerAllocation;
    },

    SET_DELETE_ACTION(state, index) {
      const campaignDisabled = state.suggestionDetail.campaigns[index];
      campaignDisabled.enabled = 2;
      campaignDisabled.status = 2;
      state.suggestionDetail.campaigns.splice(index, 1, campaignDisabled);
    },

    SET_ACTIVE_CAMPAIGN(state, index) {
      const campaignDisabled = state.suggestionDetail.campaigns[index];
      campaignDisabled.enabled = 1;
      campaignDisabled.status = 1;
      state.suggestionDetail.campaigns.splice(index, 1, campaignDisabled);
    },

    SET_INACTIVE_CAMPAIGN(state, index) {
      const campaignDisabled = state.suggestionDetail.campaigns[index];
      campaignDisabled.enabled = 0;
      campaignDisabled.status = 0;
      state.suggestionDetail.campaigns.splice(index, 1, campaignDisabled);
    },

    SET_EXPORT_TOTALCOUNT(state, total) {
      state.pagination.count = total;
    },

    SET_SUGGESTION_QUARANTINE(state, payload) {
      state.suggestionDetail.quarantine = payload;
    },

    RESET(state) {
      state.suggestionDetail = cloneDeep(defaultSuggestionDetails);
    },

    SET_TAB(state, payload) {
      state.tabSelected = payload;
    },

    SET_DISABLED_BUTTON(state, payload) {
      state.disabledButton = payload;
    },

    RESET_STATUS(state) {
      state.filterStatus = '';
    },

    DISABLE_CAMPAIGNS_LOADING(state, payload) {
      state.disableCampaignsLoading = payload;
    },
  },
  actions: {
    setActivePage({ commit }, payload) {
      commit('SET_ACTIVE_PAGE', payload);
    },

    setPriorityDataModified({ commit }, payload) {
      commit('SET_PRIORITY_DATA_MODIFIED', payload);
    },

    setOpenConfirmEditPriorityModal({ commit }, payload) {
      commit('SET_OPEN_CONFIRM_EDIT_PRIORITY_MODAL', payload);
    },

    setMode({ commit }, payload) {
      commit('SET_MODE', payload);

      if (payload === 'create') {
        commit('SET_SUGGESTION_ACTION_TEMPLATES', []);
      }
    },

    setStatusTooltip({ commit }, payload) {
      commit('SET_STATUS_TOOLTIP', payload);
    },

    setActionCategoryList({ commit }, payload) {
      commit('SET_ACTION_CATEGORY_LIST', payload);
    },

    // list data
    getActions({ commit, state }) {
      commit('SET_LOADING_SUGGESTIONS', true);
      commit('SET_SUGGESTIONS', []);
      getActions({
        page: state.pagination.page,
        limit: state.pagination.limit,
      })
        .then(({ data }) => {
          commit('SET_SUGGESTIONS', data?.data);
          commit('SET_EXPORT_TOTALCOUNT', data?.totalCount);
        })
        .catch(() => {
          commit('SET_LOADING_SUGGESTIONS', false);
        })
        .finally(() => {
          commit('SET_LOADING_SUGGESTIONS', false);
        });
    },

    getActionTemplates({ commit }, id) {
      commit('SET_LOADING_ACTION_TEMPLATES', true);

      getActionTemplates(id)
        .then(({ data }) => {
          commit('SET_SUGGESTION_ACTION_TEMPLATES', data?.data);
        })
        .catch(() => {
          commit('SET_LOADING_ACTION_TEMPLATES', false);
        })
        .finally(() => {
          commit('SET_LOADING_ACTION_TEMPLATES', false);
        });
    },
    // campaigns
    getActionCampaigns({ state, commit }, id) {
      const loadingStatus = state.disableCampaignsLoading ? false : true;

      commit('SET_LOADING_CAMPAIGNS', loadingStatus);

      getActionCampaigns(id)
        .then(({ data }) => {
          commit('SET_SUGGESTION_CAMPAIGNS', data?.data);
        })
        .catch(() => {
          commit('SET_LOADING_CAMPAIGNS', false);
        })
        .finally(() => {
          commit('SET_LOADING_CAMPAIGNS', false);
          commit('DISABLE_CAMPAIGNS_LOADING', false);
        });
    },

    // detail data
    getSuggestionCampaign({ state, commit, dispatch }, id) {
      commit('SET_LOADING_SUGGESTIONS_DETAIL', true);

      getAction(id)
        .then(({ data }) => {
          const action = data.data;

          commit('SET_SUGGESTION_DETAILS', action);
          commit('SET_SUGGESTION_IS_ENABLED_APPROVAL', action.enabledApproval);
          commit('SET_SUGGESTION_STORES_ID', action.stores);

          if (state.mode === 'edit' && !state.suggestionDetail.actionCategory && state.actionCategoryList.length) {
            dispatch('setStatusTooltip', {
              key: 'actionCategory',
              value: true,
            });
          }
        })
        .catch(() => {
          commit('SET_LOADING_SUGGESTIONS_DETAIL', false);
        })
        .finally(() => {
          commit('SET_LOADING_SUGGESTIONS_DETAIL', false);
        });
    },

    showToastSuccess({ commit }) {
      commit('SET_SHOW_TOAST_SUCCESS', true);
    },

    hideToastSuccess({ commit }) {
      commit('SET_SHOW_TOAST_SUCCESS', false);
    },
    setEnabledApproval({ commit }) {
      commit('SET_SUGGESTION_ENABLED_APPROVAL');
    },

    updateSuggestionStores({ commit }, payload) {
      commit('SET_SUGGESTION_STORES', payload);
    },

    updateSearchTerm({ commit }, term) {
      commit('SET_LOADING_SUGGESTIONS', true);
      commit('SET_SUGGESTIONS', []);
      getActions({ term })
        .then(({ data }) => {
          commit('SET_SUGGESTIONS', data?.data);
          commit('SET_EXPORT_TOTALCOUNT', data?.totalCount);
        })
        .catch(() => {
          commit('SET_LOADING_SUGGESTIONS', false);
        })
        .finally(() => {
          commit('SET_LOADING_SUGGESTIONS', false);
        });
    },

    updateListApprovalTerm({ commit }, term) {
      commit('SET_SEARCH_TERM', term);
      bus.$emit('updateApprovalTerm');
    },

    updateFilterStatus({ commit }, value) {
      commit('SET_FILTER_STATUS', value);
      bus.$emit('updateFilterStatus');
    },

    resetStatus({ commit }) {
      commit('RESET_STATUS');
    },

    setActionId({ commit, dispatch }, id) {
      commit('SET_ID', id);
      dispatch('getSuggestionCampaign', id);
      dispatch('getActionCampaigns', id);
      dispatch('getActionTemplates', id);
    },

    updateActionCampaigns({ commit, dispatch }, id) {
      commit('DISABLE_CAMPAIGNS_LOADING', true);

      dispatch('getActionCampaigns', id);
    },

    updateSuggestionName({ commit }, payload) {
      commit('SET_SUGGESTION_NAME', payload);
    },

    updateSuggestionDescription({ commit }, payload) {
      commit('SET_SUGGESTION_DESCRIPTION', payload);
    },

    updateDates({ commit }, payload) {
      commit('SET_ACTION_STARTEND_DATES', payload);
    },

    updateCampaigns({ commit }, payload) {
      commit('SET_SUGGESTION_CAMPAIGNS', payload);
    },

    updateQuarantine({ commit }, payload) {
      commit('SET_SUGGESTION_QUARANTINE', payload);
    },

    resetData({ commit }) {
      commit('RESET');
    },

    actionSubmit({ commit, getters, dispatch }) {
      commit('SET_DISABLED_BUTTON', true);

      const {
        id,
        name,
        description,
        enabledApproval,
        stores,
        dayOfWeek,
        dayOfYear,
        startDate,
        endDate,
        campaigns,
        actionCategory,
      } = getters.getSuggestionDetail;

      const isEdit = getters.getMode === 'edit';

      const campaignsFormatted = campaigns
        ? campaigns.map(el => {
            return {
              campaignId: el?.id,
              name: el?.name.includes('(Inativa)') ? el?.name.replace('(Inativa)', '').trim() : el?.name,
              audienceId: el?.audience?.id,
              enabled: el?.enabled,
              sellerAllocation: el?.sellerAllocation,
              limitType: el?.limitType,
              limitGroupRemaining: el?.limitGroupRemaining,
              limitData:
                el?.limitData?.length > 0
                  ? el?.limitData?.map(item => {
                      return { ...item, stores: item?.stores?.comparisonStores?.join() };
                    })
                  : el?.limitData,
            };
          })
        : null;

      if (!isEdit) {
        putAction({
          name,
          description,
          enabledApproval,
          stores,
          startDate,
          endDate,
          dayOfWeek,
          dayOfYear,
          campaigns: JSON.stringify(campaignsFormatted),
          actionCategory,
        })
          .then(({ data }) => {
            if (!data.success) throw Error;

            if (data.success) {
              commit('SET_SHOW_TOAST_SUCCESS', true);
              commit('SET_DISABLED_BUTTON', false);

              if (router.currentRoute.value.meta.title === 'campaign-new') {
                setTimeout(() => {
                  router.push({
                    name: 'campaign-new',
                    query: { edit: 'priority' },
                  });
                }, 2000);
              } else {
                setTimeout(() => {
                  router.push({
                    name: 'oto-actions-edit-old',
                    params: {
                      id: data?.data.id,
                    },
                  });
                }, 2000);

                bus.$emit('suggestion-trigger-reload', { id: data?.data.id });
              }
            }
          })
          .catch(() => {
            bus.$emit('open-suggestion-campaign-modal-error');
            commit('RESET');
          })
          .finally(() => {
            dispatch('hideToastSuccess');
            dispatch('setStatusTooltip', { key: 'name', value: false });
            dispatch('setStatusTooltip', { key: 'actionCategory', value: false });
            commit('SET_DISABLED_BUTTON', false);
          });
      }

      if (isEdit) {
        updateAction({
          actionId: id,
          name,
          description,
          enabledApproval,
          stores,
          startDate,
          endDate,
          dayOfWeek,
          dayOfYear,
          campaigns: JSON.stringify(campaignsFormatted),
          actionCategory,
        })
          .then(({ data }) => {
            if (!data.success) throw Error;
            dispatch('getSuggestionCampaign', data?.data?.id);

            if (data.success) {
              commit('SET_SHOW_TOAST_SUCCESS', true);
              commit('SET_DISABLED_BUTTON', false);

              if (router.currentRoute.value.meta.title === 'campaign-new') {
                if (data.data.enabled) {
                  setTimeout(() => {
                    router.push({
                      name: 'campaign-new',
                      query: { edit: 'priority' },
                    });
                  }, 2000);

                  commit('SET_TAB', 'active');
                } else {
                  setTimeout(() => {
                    router.push({
                      name: 'campaign-new',
                    });
                  }, 2000);

                  commit('SET_TAB', 'deactive');
                }
              } else {
                setTimeout(() => {
                  router.push({
                    name: 'oto-actions-edit-old',
                    params: {
                      id: data?.data.id,
                    },
                  });
                }, 2000);
              }
            }
          })
          .catch(() => {
            bus.$emit('open-suggestion-campaign-modal-error');
            commit('RESET');
          })
          .finally(() => {
            dispatch('hideToastSuccess');
            dispatch('setStatusTooltip', { key: 'name', value: false });
            dispatch('setStatusTooltip', { key: 'actionCategory', value: false });
            commit('SET_DISABLED_BUTTON', false);
          });
      }
    },

    actionSaveAudienceCampaigns({ commit }, payload) {
      commit('SET_AUDIENCE_CAMPAIGNS', payload);
    },

    actionEditAudienceCampaigns({ commit }, payload) {
      commit('SET_EDIT_AUDIENCE_CAMPAIGNS', payload);
    },

    actionDeleteCampaign({ commit }, index) {
      commit('SET_DELETE_ACTION', index);
    },

    actionActiveCampaign({ commit }, index) {
      commit('SET_ACTIVE_CAMPAIGN', index);
    },

    actionInactiveCampaign({ commit }, index) {
      commit('SET_INACTIVE_CAMPAIGN', index);
    },
  },
  getters: {
    getActivePage: state => state.activePage,
    getMode: state => state.mode,
    getActionsList: state => state.suggestionsList,
    getSuggestionDetail: state => state.suggestionDetail,
    getLoadingStatus: state => state.loadingSuggestions,
    getLoadingAction: state => state.loadingSuggestionsDetail,
    getLoadingCampaigns: state => state.loadingCampaigns,
    getLoadingActionTemplates: state => state.loadingActionTemplates,
    getIsEmptyCategoryTooltipVisible: state => state.tooltipEmptyError.actionCategory,
    getIsEmptyNameTooltipVisible: state => state.tooltipEmptyError.name,
    getFilteredSuggestionList: state => () => {
      if (!state.term) return state.suggestionsList;
      // applyParse => add scape regex characters and ignore accents from characters on term argument
      const applyParse = escapeRegExp(stringParser(state.term));
      return state.suggestionsList.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.action)));
    },
    getStoresId: state => state.suggestionDetail.storesId?.map(store => store.id),
    getSearchTermListApproval: state => state.term,
    getLimitsSelectOptions: state => state.limitsSelectOptions,
    hasEmptyRequiredFields: state => !state.suggestionDetail?.actionCategory || !state.suggestionDetail?.name,
    getActionTemplates: state => state.actionTemplates,
  },
};

export default suggestions;
