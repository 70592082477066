<template>
  <el-dialog
    v-model="isVisible"
    :id="id"
    class="modal-process-progress"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <h3>{{ $t('spinner') }}</h3>
    <div class="progressive-bar">
      <span>{{ progressBar }}%</span>
      <div>
        <span class="pb__load" :style="{ width: progressBar + '%' }" data-cy="progress-bar-percentage"> </span>
      </div>
    </div>
    <button class="btn btn-outline-ctx-primary" @click="cancelExportImportRequest()">{{ $t('cancel') }}</button>
  </el-dialog>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';

// components
import { cancelExportImportJob } from '@/services/audiences';

export default {
  name: 'ModalProcessProgress',
  props: {
    id: {
      type: String,
      default: '',
    },
    batchId: {
      type: String,
      default: '',
    },
    isCloseModal: {
      type: Boolean,
      default: false,
    },
    progressBar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      progress: 0,
      isCloseModalProps: false,
      isVisible: false,
    };
  },
  watch: {
    progressBar() {
      if (this.progressBar === 100) {
        bus.$emit(`close-${this.id}`);
      }
    },
    isCloseModal() {
      if (this.isCloseModal) {
        this.closeModal();
      }
    },
    isVisible() {
      if (!this.isVisible) {
        if (this.batchId && !this.isCloseModal) this.cancelExportImportRequest();
      }
    },
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
  },
  beforeMount() {
    bus.$off(`open-${this.id}`);
  },
  methods: {
    /**
     * @description modal events
     */
    openModal() {
      this.isVisible = true;
      this.progress = 0;
    },
    closeModal() {
      this.isVisible = false;
      if (this.batchId && !this.isCloseModal) this.cancelExportImportRequest();

      this.$emit('update:isCloseModal', false);
    },

    checkRequestStatus() {},

    cancelExportImportRequest() {
      if (this.batchId) cancelExportImportJob(this.batchId).then();
    },
  },
};
</script>

<style lang="scss">
.modal-process-progress {
  width: 400px !important;
  height: 232px;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 67px;

    h3,
    span {
      font-weight: 600;
      color: $gray-800;
    }

    .progressive-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: 30px 0 45px;

      span {
        width: 15%;
      }

      div {
        display: flex;
        width: 85%;
        border-radius: 8px;
        border: 1px solid $gray-300;
        height: 10px;
        overflow: hidden;

        span {
          margin: unset;
          background: $blue-500;
          border-radius: 8px;
          transition: width ease 0.5s;
        }
      }
    }

    .btn-outline-ctx-primary {
      background-color: $color-white !important;
      color: $oto-omni !important;
      border: 1px solid $oto-omni !important;
      width: 180px;
    }
  }
}
</style>
