import router from '@/router';
import bus from '@/helpers/events/bus';
import { validate } from '@/helpers/audiences';
import { getDataFiltered } from '@/helpers/filter-audiences-and-labels';
import { getCount, getQuery, getColumnsChained, getDescription } from '@/services/audiences';
import { getConnectors } from '@/services/connectors';
import { omnichatIndividualApproachStartDate } from '@/utilities/constants';

const handleInitialInteraction = state => {
  if (!state.hasInitialInteraction) {
    state.hasInitialInteraction = true;
  }

  state.loadingCount = true;
};

const resetCountStateIfQueryEmpty = (state, commit) => {
  commit('CHANGE_SCORE', null);
  commit('CHANGE_COUNT_PEOPLE', null);
  state.hasInitialInteraction = false;
  state.loadingCount = false;
  state.pendingRequestCount = 0;
};

const clearDebounceTimer = state => {
  if (state.debounceTimer) {
    clearTimeout(state.debounceTimer);
    state.debounceTimer = null;
  }
};

const defaultState = () => ({
  selectedStep: 1,
  redirectToIntegrations: false,
  reference: null,
  creator: null,
  isSerieAdded: false,
  name: null,
  debounceTimer: null,
  description: null,
  errorCount: false,
  include: [],
  exclude: [],
  serieFill: {},
  people: null,
  token: null,
  score: null,
  loadingCount: false,
  resetDebounceCount: false,
  lastUpdateCount: null,
  lastQuery: '',
  hasInitialInteraction: false,
  exporting: null,
  requestCount: 0,
  integrationSeries: [],
  enabledIntegrations: [],
  loading: true,
  isLookALikeActive: false,
  expansion: null,
  lookALikeData: {},
  rangeVal: 0,
  isOmnichatAndIndividualApproach: false,
  loadIntegrationsFromRequest: [],
  pendingRequestCount: 0,
});

const audience = {
  namespaced: true,
  state: defaultState(),
  getters: {
    isView: state =>
      state.reference !== undefined &&
      (router.currentRoute.value.name === 'AudienceDetail' || router.currentRoute.value.name === 'AudienceDetailOld'),
    formateColumns:
      (state, getters) =>
      (keys, fill, isLastLevel = false) => {
        const options = [];

        keys.forEach(key => {
          const chaves = Object.keys(fill[key]);
          const values = isLastLevel ? fill[key] : getters.formateColumns(chaves, fill[key], true);
          options.push({
            key,
            ...(isLastLevel ? values : { columns: values }),
          });
        });

        return options;
      },
    isValid: (state, getters) => {
      const errors = [
        validate.groups(state.include, getters.columnOptions),
        validate.groups(state.exclude, getters.columnOptions),
      ];
      return errors.every(arg => arg);
    },
    isQueryEmpty: (state, getters) => {
      return !Object.keys(getters.query).length;
    },
    isConnectValid: state => {
      if (!state.enabledIntegrations.length) return true;
      return validate.connect(state.enabledIntegrations);
    },
    isDuplicate: state => state.reference !== undefined,
    query: state => {
      const obj = {};
      // validate if include/exclude array is not empty and check the length of first index
      if (state.include.length && state.include[0].length) obj.include = state.include;
      if (state.exclude.length && state.exclude[0].length) obj.exclude = state.exclude;
      return obj;
    },
    columnOptions: (state, getters) => {
      const { serieFill } = state;
      const keys = Object.keys(serieFill);

      return getters.formateColumns(keys, serieFill);
    },
    compositionLoading: (state, getters) => {
      if ((getters.isView || getters.isDuplicate) && !state.lastQuery) return true;
      return state.loading;
    },
    countPeopleLookALike(state) {
      return state.people > 1000 && state.people < 100000;
    },
    hasEmptyEntries(state) {
      return !state.include.length && !state.exclude.length;
    },
    getIntegrationsByAudience: state => state.loadIntegrationsFromRequest,
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    setAudienceID({ commit }, id) {
      commit('SET_AUDIENCEID', id);
    },
    setCreator({ commit }, creatorID) {
      commit('SET_CREATOR', creatorID);
    },
    setDescription({ commit }, description) {
      commit('SET_DESCRIPTION', description);
    },
    setName({ commit }, name) {
      commit('SET_NAME', name);
    },
    addGroup({ commit }, { include = true } = {}) {
      commit('ADD_GROUP', include);
    },
    addSerie({ commit, dispatch, state }, data) {
      commit('ADD_SERIE', data);

      if (!state.isSerieAdded) {
        state.pendingRequestCount += 1;
        state.isSerieAdded = true;
        state.loadingCount = true;
      }

      handleInitialInteraction(state);

      dispatch('triggerDebounceReset');
    },
    updateSerie({ commit, dispatch, state }, data) {
      commit('UPDATE_SERIE', data);

      handleInitialInteraction(state);

      dispatch('triggerDebounceReset');
    },
    removeGroup({ commit, dispatch, state }, data) {
      commit('REMOVE_GROUP', data);

      handleInitialInteraction(state);

      dispatch('triggerDebounceReset');
    },
    removeSerie({ commit, dispatch, state, getters }, data) {
      commit('REMOVE_SERIE', data);

      if (getters.isQueryEmpty) {
        resetCountStateIfQueryEmpty(state, commit);
        clearDebounceTimer(state);

        return;
      }

      handleInitialInteraction(state);
      dispatch('triggerDebounceReset');
    },
    resetInclude({ commit }, value) {
      commit('RESET_INCLUDE', value);
    },
    resetExclude({ commit }, value) {
      commit('RESET_EXCLUDE', value);
    },
    updateLoading({ commit }, value) {
      commit('UPDATE_LOADING', value);
    },
    changeStep({ commit }, step) {
      commit('CHANGE_STEP', step);
    },
    redirectToIntegrations({ state }, value) {
      state.redirectToIntegration = value;
    },
    updateLookALikeStatus({ commit, dispatch }) {
      commit('UPDATE_LOOKALIKE_STATUS');
      dispatch('updateCount');
    },
    expansionLookALike({ commit }, value) {
      commit('UPDATE_EXPANSION', value);
    },
    updateRange({ commit }, value) {
      commit('UPDATE_RANGE', value);
    },
    updateLookALikeData({ commit }, value) {
      commit('UPDATE_LOOKALIKE_DATA', value);
    },
    triggerDebounceReset({ state, commit, dispatch, getters }) {
      if (getters.isView) {
        dispatch('updateCount');
        return;
      }

      if (state.redirectToIntegration) {
        dispatch('updateCount');
        commit('CHANGE_STEP', 2);

        return;
      }

      clearDebounceTimer(state);

      if (getters.isQueryEmpty && !getters.isView) {
        resetCountStateIfQueryEmpty(state, commit);

        return;
      }

      if (state.pendingRequestCount) {
        state.loadingCount = true;
      }

      commit('SET_RESET_DEBOUNCE', true);
      state.hasInitialInteraction = false;

      state.debounceTimer = setTimeout(() => {
        commit('SET_RESET_DEBOUNCE', false);

        if (!getters.isQueryEmpty) {
          state.loadingCount = true;
        }

        dispatch('updateCount');
        state.isSerieAdded = false;
      }, 2500);
    },
    updateCount({ state, getters, commit }) {
      if (state.resetDebounceCount) return;

      state.errorCount = false;
      if ((!getters.isValid || getters.isQueryEmpty) && !getters.isView) return;

      state.loadingCount = true;
      state.lastQuery = JSON.stringify(getters.query);

      // Define o ID da requisição atual
      const currentRequestId = Date.now();
      state.currentRequestId = currentRequestId;

      // if if audience is duplicate from another one and user on create view page, shan't send id to getCount
      const DUPLICATE_CREATION_MODE = !getters.isView && getters.isDuplicate;

      getCount(getters.query, DUPLICATE_CREATION_MODE ? null : state.reference, Number(state.isLookALikeActive))
        .then(response => {
          if (!response || !response.data?.success) {
            state.errorCount = true;
            return;
          }

          // Apenas processa a resposta se a última requisição for válida
          if (currentRequestId === state.currentRequestId) {
            state.people = response.data.data.count;
            state.token = response.data.data.token;
            state.score = response.data.data.score;

            bus.$emit('count-updated');
          }
        })
        .finally(() => {
          if (state.pendingRequestCount > 0) {
            state.pendingRequestCount -= 1;
          }

          if (state.pendingRequestCount === 0) {
            state.loadingCount = false;

            if (getters.isQueryEmpty && !getters.isView) {
              resetCountStateIfQueryEmpty(state, commit);
            }
          }

          state.isSerieAdded = false;
          state.hasInitialInteraction = false;
        });
    },
    /**
     * Get audience condition columns
     */
    listColumns({ commit, dispatch, state, getters }, type) {
      getColumnsChained()
        .then(data => {
          let result;
          if (type === 'create') {
            result = getDataFiltered(data?.data?.data);
          } else {
            result = data?.data?.data;
          }

          commit('UPDATE_SERIEFILL', result);

          if (getters.isDuplicate || getters.isView) {
            getQuery(state.reference).then(dataRes => {
              // check response and return to audience listing if it is false
              if (dataRes) {
                // case it is view mode update audience name
                if (getters.isView) {
                  dispatch('setName', dataRes.data.data.name);
                }
                const { include, exclude } = dataRes.data.data.query;
                dispatch('resetInclude', include || []);
                dispatch('resetExclude', exclude || []);
                dispatch('triggerDebounceReset');
              } else {
                bus.$emit('back-to-audience-list');
              }
              dispatch('updateLoading', false);
            });
            getDescription(state.reference).then(dataRes => {
              if (dataRes) {
                if (getters.isView) {
                  dispatch('setDescription', dataRes.data.data.description);
                }
              }
            });
          } else {
            dispatch('addGroup');
            dispatch('updateLoading', false);
          }
        })
        .catch(() => {
          bus.$emit('back-to-audience-list');
        });
    },
    listConnectors({ commit }) {
      getConnectors().then(response => {
        const data = response?.data?.data;
        commit('LIST_CONNECTORS', data);
      });
    },
    updateIntegration({ commit, state, dispatch }, data) {
      // get index of integration serie
      const index = state.integrationSeries.map(serie => serie.connect).indexOf(data.connect);

      if (data.alias === 'omnichat' && data.startDatetime === omnichatIndividualApproachStartDate)
        dispatch('updateIsOmnichanelAndIndividualApproach', true);

      commit('UPDATE_INTEGRATION', { index, data });
    },
    updateEnabledIntegrations({ commit }, series) {
      commit('UPDATE_ENABLED_INTEGRATIONS', series);
    },
    updateExporting({ commit }, id) {
      commit('UPDATE_EXPORTING', id);
    },
    updateIsOmnichanelAndIndividualApproach({ commit }, value) {
      commit('UPDATE_IS_OMNICHAT_AND_INDIVIDUAL_APPROACH', value);
    },
    getIntegrationsFromRequest({ commit }, value) {
      commit('LOAD_INTEGRATIONS_FROM_REQUEST', value);
    },
  },
  mutations: {
    // reset state
    RESET(state) {
      const initial = defaultState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    /**
     * Set audience id
     * @param {*} state
     * @param {String} id audience id
     */
    SET_AUDIENCEID(state, id) {
      state.reference = id;
    },
    /**
     * Set user creator
     * @param {*} state
     * @param {String} creator user creator id
     */
    SET_CREATOR(state, creator) {
      state.creator = creator;
    },

    SET_DESCRIPTION(state, description) {
      state.description = description;
    },
    /**
     * Set audience name
     * @param {*} state
     * @param {String} name audience name
     */
    SET_NAME(state, name) {
      state.name = name;
    },
    /**
     * Update list of available filters (columns)
     * @param {*} state
     * @param {Object} serieFill audience filters
     */
    UPDATE_SERIEFILL(state, serieFill) {
      state.serieFill = serieFill;
    },
    /**
     * Add new group
     * @param {*} state
     * @param {Boolean} include Set type of condition: true for include or false for exclude
     */
    ADD_GROUP(state, include) {
      if (include) {
        state.include.push([]);
      } else {
        state.exclude.push([]);
      }
    },
    /**
     * Add new serie
     * @param {*} state
     * @param {Object} data Object with serie data, condition type (boolean: include - true - or exclude - false) and group index
     */
    ADD_SERIE(state, { data, include = true, group = 0 }) {
      const condition = { ...data };

      if (include) state.include[group].push(condition);
      else state.exclude[group].push(condition);
    },
    /**
     * Update serie data
     * @param {*} state
     * @param {Object} data Object with serie index, group index, condition type (boolean: include - true - or exclude - false) and serie data
     */
    UPDATE_SERIE(state, { serie, group, include, data }) {
      const condition = { ...data };

      if (include) state.include[group][serie] = condition;
      else state.exclude[group][serie] = condition;
    },
    /**
     * Remove group
     * @param {*} state
     * @param {Object} data Object with condition type (include or exclude) and group index
     */
    REMOVE_GROUP(state, { include = true, group }) {
      if (include) {
        if (state.include.length > 1) {
          state.include.splice(group, 1);
        }
      } else {
        state.exclude.splice(group, 1);
      }
    },
    /**
     * Remove serie
     * @param {*} state
     * @param {Object} data Object with condition type (include or exclude), group index and serie index
     */
    REMOVE_SERIE(state, { include = true, group, serie }) {
      if (include) {
        state.include[group].splice(serie, 1);
      } else {
        state.exclude[group].splice(serie, 1);
      }
    },
    /**
     * Reset include Array
     * @param {*} state
     * @param {Array} newValue Empty (create) or condition (view/duplicate)
     */
    RESET_INCLUDE(state, newValue) {
      state.include = newValue;
    },
    /**
     * Reset exclude Array
     * @param {*} state
     * @param {Array} newValue Empty (create) or condition (view/duplicate)
     */
    RESET_EXCLUDE(state, newValue) {
      state.exclude = newValue;
    },
    /**
     * Update value of loading property
     * @param {*} state
     * @param {Boolean} newValue true or false
     */
    UPDATE_LOADING(state, newValue) {
      state.loading = newValue;
    },
    /**
     * Change selected step
     * @param {*} state
     * @param {Number} step  1 - Condition, 2 - Integration or 3 - Historic
     */
    CHANGE_STEP(state, step) {
      state.selectedStep = step;
    },
    /**
     * Change score
     * @param {*} state
     * @param {Number} value
     */
    CHANGE_SCORE(state, value) {
      state.score = value;
    },
    /**
     * Change count people
     * @param {*} state
     * @param {Number} value
     */
    CHANGE_COUNT_PEOPLE(state, value) {
      state.people = value;
    },
    /**
     * List available services
     * @param {*} state
     * @param {Object} services service list
     */
    LIST_CONNECTORS(state, services) {
      if (!services) return;
      state.integrationSeries = services.map(connector => ({
        connect: connector.alias,
        alias: connector.alias,
        icon: connector.icon,
        type: connector.type,
        title: connector.name,
        schedule: undefined,
        hasDatetime: undefined,
        startDatetime: undefined,
        enabled: false,
        editable: true,
      }));
    },
    /**
     * Update a integration service
     * @param {*} state
     * @param {Object} data Object with index and data of connector;
     */
    UPDATE_INTEGRATION(state, { index, data }) {
      // list of connector properties
      const keys = Object.keys(data || {});
      keys.forEach(prop => {
        // set property value
        state.integrationSeries[index][prop] = data[prop];
      });
    },
    /**
     * Filter enabled integrations
     * @param {*} state
     * @param {Array} series List of integration options
     */
    UPDATE_ENABLED_INTEGRATIONS(state, series) {
      state.enabledIntegrations = series.filter(serie => (!!serie.enabled && !serie.id) || serie.id);
    },
    /**
     * Set audience information to export
     * @param {*} state
     * @param {Number} aid Audience ID (Optional)
     */
    UPDATE_EXPORTING(state, aid) {
      state.exporting = {
        id: state.reference || aid,
        name: state.name,
        contacts: state.people,
      };
    },
    /**
     * Update look a like status
     * @param {*} state
     * @param {Boolean} status
     */
    UPDATE_LOOKALIKE_STATUS(state) {
      state.isLookALikeActive = !state.isLookALikeActive;
    },
    /**
     * Update expansion
     * @param {*} state
     * @param {number} value
     */
    UPDATE_EXPANSION(state, value) {
      state.expansion = value;
    },
    /**
     * Update expansion
     * @param {*} state
     * @param {number} value
     */
    UPDATE_RANGE(state, value) {
      state.rangeVal = value;
    },
    /**
     * Update expansion data
     * @param {*} state
     * @param {Object} value
     */
    UPDATE_LOOKALIKE_DATA(state, value) {
      state.lookALikeData = value;
    },

    UPDATE_IS_OMNICHAT_AND_INDIVIDUAL_APPROACH(state, value) {
      state.isOmnichatAndIndividualApproach = value;
    },
    LOAD_INTEGRATIONS_FROM_REQUEST(state, value) {
      state.loadIntegrationsFromRequest = value;
    },
    SET_RESET_DEBOUNCE(state, value) {
      state.resetDebounceCount = value;
    },
  },
};

export default audience;
